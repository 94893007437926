import { Injectable } from '@angular/core';

import { ActionType, CancelOptions, LocalNotifications, LocalNotificationSchema, } from '@capacitor/local-notifications';

import { NotificationActionType, StartDeviceActions } from '@app/core/model';

@Injectable({ providedIn: 'root' })
export class LocalNotificationsService {

    notificationsEnabled(): Promise<boolean> {

        return LocalNotifications.checkPermissions()
            .then(permissionStatus =>  permissionStatus.display === 'granted');
    }

    requestPermissions(): void {

        LocalNotifications.requestPermissions();
    }

    registerAppActionTypes(): Promise<void> {

        const types: ActionType[] = [
            {
                id: NotificationActionType.StartDevice,
                actions: [
                    {
                        id: StartDeviceActions.Start,
                        title: 'Start'
                    }
                ]
            }
        ];

        return LocalNotifications.registerActionTypes({ types });
    }

    schedule(notifications: LocalNotificationSchema[]): Promise<number[]> {

        // Ensure action types are registered
        return this.registerAppActionTypes()
            .then(() => LocalNotifications.schedule({
                notifications
            }))
            .then(scheduleResult => scheduleResult.notifications.map(n => n.id));
    }

    cancel(notificationIds: number[]): Promise<void> {

        const cancelOptions: CancelOptions = {
            notifications: notificationIds.map(id => ({
                id
            }))
        };

        return LocalNotifications.cancel(cancelOptions);
    }

    getNextNoficationId(): Promise<number> {

        return LocalNotifications.getPending()
            .then(({ notifications }) => {

                if (notifications.length === 0) {
                    return 1;
                }

                const nextId = Math.max(...notifications.map(n => n.id)) + 1;
                return nextId;
            });

    }
}
