import { Component } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';

import {
    PlatformVisibilityService,
    LocalNotificationHandlerSerivce
} from './core/services';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    public appPages = [
        {
            title: 'Wake on Lan',
            url: '/app/main/wol',
            icon: null // 'calendar'
        },
        {
            title: 'Pending Notifications',
            url: '/pending-notifications',
            icon: null // 'information-circle'
        },
        {
            title: 'About',
            url: '/about',
            icon: null // 'information-circle'
        }
    ];

    public configPages = [
        {
            title: 'Setup',
            url: '/setup',
            icon: null // 'information-circle'
        }
    ];

    constructor(
        private platform: Platform,
        private localNotificationHandlerSerivce: LocalNotificationHandlerSerivce,
        private platformVisibilityService: PlatformVisibilityService
    ) {
        this.initializeApp();
    }

    initializeApp() {

        this.platform.ready().then(() => {

            if (this.platform.is('capacitor')) {

                SplashScreen.hide({ fadeOutDuration: 0 });
                StatusBar.setStyle({ style: Style.Dark });

                this.localNotificationHandlerSerivce.init();
                this.platformVisibilityService.init();
            }
        });
    }
}
