import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { IWolSetup } from '../model';
import { Injectable } from '@angular/core';
import { BaseService } from './base-service';

@Injectable({ providedIn: 'root' })
export class WolServerService extends BaseService {

    constructor(
        private http: HttpClient
    ) {
        super();
    }

    checkWolServerConnection(wolSetup: IWolSetup): Observable<boolean> {

        const url = wolSetup.url.trim() + '/api/health';
        return this.http.get(url, {
            headers: {
                ...this.createAuthHeaders(wolSetup)
            }
        }).pipe(
            map(() => true),
            catchError(error => {

                if (error instanceof HttpErrorResponse) {

                    if (error.status === 0) {
                        return throwError(new Error(`WoL Server couldn't be reached`));
                    } else if (error.status === 401) {
                        return throwError(new Error(`WoL Server authentication failed`));
                    }
                }
                return throwError(error);
            })
        );
    }
}
