import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Store } from '@ngrx/store';

import * as fromSelectors from '@app/pages/wol/store/selectors/device.seclectors';

@Injectable({
    providedIn: 'root'
})
export class HasDevicesLoadedGuard implements CanActivateChild, CanActivate {

    constructor(
        private router: Router,
        private store: Store<any>
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this._canActivate();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this._canActivate();
    }

    private _canActivate(): Observable<boolean | UrlTree> {

        return this.store.select(fromSelectors.getLoaded)
            .pipe(
                map(loaded => {

                    if (loaded) {
                        return true;
                    }

                    const url = '/app/main/wol';
                    const tree: UrlTree = this.router.parseUrl(url);

                    return tree;
                })
            );
    }
}
