import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';

export const getDevicesState = createSelector(fromFeature.getWolFeatureSelector, (state) => state.devices);

export const getLoading = createSelector(getDevicesState, (state) => state.loading);
export const getLoaded = createSelector(getDevicesState, (state) => state.loaded);

export const getEntities = createSelector(getDevicesState, (state) => state.entities);

export const getItems = createSelector(getEntities, entities => {
    return Object.keys(entities).map(id => entities[id]);
});

export const getDeviceById = (id: string) => createSelector(
    getEntities,
    entities => entities[id]
);

export const getStartingDevices = createSelector(getDevicesState, (state) => state.startingDevices);

export const getIsDeviceStarting = (id: string) => createSelector(
    getStartingDevices,
    startingDevices => startingDevices[id]
);
