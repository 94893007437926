import * as fromActions from '@app/core/store/actions/performed-actions.actions';
import { IPerformedAction } from '@app/core/model';

export interface IPerfomedActionsState {
    performedAction: IPerformedAction;
}

export const initialState: IPerfomedActionsState = {
    performedAction: null
};

export function reducer(state = initialState, action: fromActions.PerformedAction): IPerfomedActionsState {

    switch (action.type) {

        case fromActions.ACTION_PERFORMED: {

            const { performedAction } = action.payload;

            return {
                ...state,
                performedAction
            };
        }

        case fromActions.PERFORMED_ACTION_HANDLED: {

            return {
                ...state,
                performedAction: null
            };
        }
    }

    return state;
}
