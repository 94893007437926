import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, CanActivate, CanLoad } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StorageService } from '@app/core/services';


@Injectable({
    providedIn: 'root'
})
export class HasValidSetupGuard implements CanActivateChild, CanActivate, CanLoad {

    constructor(
        private router: Router,
        private storageService: StorageService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this._allowValidSetupRedirectOtherwise();
    }

    canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this._allowValidSetupRedirectOtherwise();
    }

    canLoad(): Observable<boolean> {

        return this.storageService.isWolSetupValid().pipe(
            map(isValid => {

                if (isValid) {
                    return true;
                }

                this.router.navigateByUrl(this._getSetupUrl());
                return false;
            })
        );
    }

    private _allowValidSetupRedirectOtherwise(): Observable<boolean | UrlTree> {

        return this.storageService.isWolSetupValid()
            .pipe(
                map(isValid => {

                    if (isValid) {
                        return true;
                    }

                    return this._getSetupUrl();
                })
            );
    }

    private _getSetupUrl(): UrlTree {

        const url = 'setup';
        return this.router.parseUrl(url);
    }


}
