import { Injectable, NgZone } from '@angular/core';

import { ActionPerformed as LocalNotificationActionPerformed, LocalNotifications } from '@capacitor/local-notifications';

import { Store } from '@ngrx/store';

import {
    INotificationExtra,
} from '../model';

import { ActionPerformed } from '../store/actions';

@Injectable({ providedIn: 'root' })
export class LocalNotificationHandlerSerivce {

    constructor(
        private store: Store<any>,
        private ngZone: NgZone
    ) {

        // const extra: IStartupDeviceNotificationExtra = {
        //     type: NotificationType.StartDevice,
        //     deviceId: '5cc96fbb2e0927001a7fe936',
        //     schedule: {
        //         weekday: 1,
        //         hour: 15,
        //         minute: 30
        //     }
        // };

        // this._localNotificationActionPerformed({
        //     actionId: 'tap',
        //     inputValue: null,
        //     notification: {
        //         id: 1,
        //         actionTypeId: '',
        //         title: 'Startup Reminder',
        //         body: 'Start device',
        //         extra
        //     }
        // });
    }

    init(): void {

        this.attachListeners();
    }

    attachListeners(): void {

        LocalNotifications.addListener('localNotificationActionPerformed',
            (action) => this.ngZone.run(() => this._localNotificationActionPerformed(action)));
    }

    private _localNotificationActionPerformed = (action: LocalNotificationActionPerformed) => {

        const performedAction: LocalNotificationActionPerformed = {
            ...action,
            notification: {
                ...action.notification,
                id: action.notification.id
            }
        };

        console.log('localNotificationActionPerformed', performedAction);

        const extra: INotificationExtra = performedAction.notification.extra;
        if (!extra || extra.type === undefined) {

            console.warn('Unrecognized notfication action performed');
            return;
        }

        this.store.dispatch(new ActionPerformed({
            performedAction: {
                type: extra.type,
                action: performedAction
            }
        }));
    }
}
