import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';

import { HasValidSetupGuard } from './shared/guards';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'app/main/wol',
        pathMatch: 'full'
    },
    {
        path: 'setup',
        loadChildren: () => import('./pages/setup/setup.module').then(m => m.SetupPageModule)
    },
    {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule)
    },
    {
        path: 'app',
        loadChildren: () => import('./pages/main/main.module').then(m => m.MainPageModule),
        canLoad: [HasValidSetupGuard]
    },
    {
        path: 'pending-notifications',
        loadChildren: () => import('./pages/pending-notifications/pending-notifications.module').then(m => m.PendingNotificationsPageModule)
    }
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules
        })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
