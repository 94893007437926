import { Action } from '@ngrx/store';

import { IPerformedAction } from '@app/core/model';

export const ACTION_PERFORMED = '[Performed Actions] Action performed';
export const PERFORMED_ACTION_HANDLED = '[Performed Actions] Performed action handled';

export class ActionPerformed implements Action {
    readonly type = ACTION_PERFORMED;
    constructor(
        public payload: {
            performedAction: IPerformedAction
        }
    ) { }
}

export class PerformedActionHandled implements Action {
    readonly type = PERFORMED_ACTION_HANDLED;
}

export type PerformedAction =
    | ActionPerformed
    | PerformedActionHandled
    ;
