import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromPerformedActionsReducer from './performed-actions.reducer';

export interface ICoreState {
    performedActions: fromPerformedActionsReducer.IPerfomedActionsState;
}

export const reducers: ActionReducerMap<ICoreState> = {
    performedActions: fromPerformedActionsReducer.reducer
};

export const getCoreRootState = (state: ICoreState) => state;
