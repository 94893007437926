import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';

import { ActionPerformed } from '@capacitor/local-notifications';

import { map } from 'rxjs/operators';

import { Actions, Effect, ofType } from '@ngrx/effects';

import * as fromActions from '@app/core/store/actions/performed-actions.actions';
import { INotificationExtra, NotificationType, NotificationDefaultActionIdentifier, StartDeviceActions } from '@app/core/model';

@Injectable()
export class PerformedActionsEffect {

    constructor(
        private actions$: Actions,
        private router: Router,
        private ngZone: NgZone
    ) { }

    @Effect({ dispatch: false })
    actionPerformed$ = this.actions$.pipe(
        ofType(fromActions.ACTION_PERFORMED),
        map((action: fromActions.ActionPerformed) => action.payload),
        map(({ performedAction }) => {

            switch (performedAction.type) {
                case NotificationType.StartDevice: {

                    this._handleStartDeviceAction(performedAction.action);
                    break;
                }
            }
        })
    );

    private _handleStartDeviceAction(action: ActionPerformed): void {

        console.log(action);

        switch (action.actionId) {
            case NotificationDefaultActionIdentifier: {

                // Notification was only tapped, no action type was used
                this._navigateTo('/app/main/wol');
                break;
            }
            case StartDeviceActions.Start: {

                break;
            }
        }
    }

    private _navigateTo(route: string): void {

        // this.ngZone.run(() => 
        this.router.navigate([route]);
        // );
    }
}
