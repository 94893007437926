import { CheckSetupGuard } from './check-setup.guard';
import { HasValidSetupGuard } from './has-valid-setup-child.guard';
import { HasDevicesLoadedGuard } from './has-devices-loaded.guard';

export const SHARED_GUARDS = [
    CheckSetupGuard,
    HasValidSetupGuard,
    HasDevicesLoadedGuard
];

export { CheckSetupGuard } from './check-setup.guard';
export { HasValidSetupGuard as HasValidSetupGuard } from './has-valid-setup-child.guard';
export { HasDevicesLoadedGuard } from './has-devices-loaded.guard';
