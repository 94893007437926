import { StorageService } from './storage.service';
import { WakeService } from './wake.service';
import { DevicesService } from './devices.service';
import { WolServerService } from './wol-server.service';
import { LocalNotificationsService } from './local-notifications.service';
import { PlatformVisibilityService } from './platform-visibility.service';
import { StartupReminderService } from './startup-reminder.service';
import { LocalNotificationHandlerSerivce } from './local-notification-handler.service';

export const CORE_SERVICES = [
    StorageService,
    WakeService,
    DevicesService,
    WolServerService,
    LocalNotificationsService,
    PlatformVisibilityService,
    StartupReminderService,
    LocalNotificationHandlerSerivce
];

export { StorageService } from './storage.service';
export { WakeService } from './wake.service';
export { DevicesService } from './devices.service';
export { WolServerService } from './wol-server.service';
export { LocalNotificationsService } from './local-notifications.service';
export { PlatformVisibilityService } from './platform-visibility.service';
export { StartupReminderService } from './startup-reminder.service';
export { LocalNotificationHandlerSerivce } from './local-notification-handler.service';
