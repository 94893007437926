import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { WOL_API_VERSION } from '../core.tokens';
import { IDevice, INewDevice, IDeviceUpdate } from '@app/shared/model';
import { makeApiUrl } from '../util';
import { BaseService } from './base-service';

const API_ROUTE_DEVICES = 'devices';

@Injectable({ providedIn: 'root' })
export class DevicesService extends BaseService {

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        @Inject(WOL_API_VERSION) private wolApiVersion: string
    ) {
        super();
    }

    createDevice(device: INewDevice): Observable<IDevice> {

        return this.storageService
            .getWolSetup().pipe(
                mergeMap(wolSetup => {

                    const url = makeApiUrl(wolSetup.url, this.wolApiVersion, API_ROUTE_DEVICES);
                    return this.http.post<IDevice>(url, device, {
                        headers: {
                            ...this.createAuthHeaders(wolSetup)
                        }
                    }).pipe(
                        map(createdDevice => this._mapDevice(createdDevice)),
                        catchError(this._handleError)
                    );
                })
            );
    }

    readDevices(): Observable<IDevice[]> {

        return this.storageService
            .getWolSetup().pipe(
                mergeMap(wolSetup => {

                    const url = makeApiUrl(wolSetup.url, this.wolApiVersion, API_ROUTE_DEVICES);
                    return this.http.get<IDevice[]>(url, {
                        headers: {
                            ...this.createAuthHeaders(wolSetup)
                        }
                    }).pipe(
                        map(devices => devices.map(device => this._mapDevice(device))),
                        catchError(this._handleError)
                    );
                })
            );
    }

    updateDevice(id: string, device: IDeviceUpdate): Observable<IDevice> {

        return this.storageService
            .getWolSetup().pipe(
                mergeMap(wolSetup => {

                    const url = makeApiUrl(wolSetup.url, this.wolApiVersion, API_ROUTE_DEVICES, id);
                    return this.http.put<IDevice>(url, device, {
                        headers: {
                            ...this.createAuthHeaders(wolSetup)
                        }
                    }).pipe(
                        map(updatedDevice => this._mapDevice(updatedDevice)),
                        catchError(this._handleError)
                    );
                })
            );
    }

    deleteDevice(device: IDevice): Observable<IDevice> {

        return this.storageService
            .getWolSetup().pipe(
                mergeMap(wolSetup => {

                    const url = makeApiUrl(wolSetup.url, this.wolApiVersion, API_ROUTE_DEVICES, device.id);

                    return this.http.delete<IDevice>(url, {
                        headers: {
                            ...this.createAuthHeaders(wolSetup)
                        }
                    }).pipe(
                        map(removedDevice => this._mapDevice(removedDevice)),
                        catchError(this._handleError)
                    );
                })
            );
    }

    private _mapDevice(device: IDevice): IDevice {

        return {
            ...device,
            createdAt: device.createdAt ? new Date(device.createdAt) : undefined,
            updatedAt: device.updatedAt ? new Date(device.updatedAt) : undefined,
        };
    }

    private _handleError(error: any): Observable<any> {

        console.error(error);

        return throwError(error);
    }
}
