import { Action } from '@ngrx/store';
import { IDevice, INewDevice, IDeviceUpdate } from '@app/shared/model';

export const CREATE_DEVICE = '[Devices] Create device';
export const CREATE_DEVICE_SUCCESS = '[Devices] Create device success';
export const CREATE_DEVICE_FAILURE = '[Devices] Create device failure';

export class CreateDevice implements Action {
    readonly type = CREATE_DEVICE;
    constructor(
        public payload: {
            successRoute: string,
            newDevice: INewDevice
        }
    ) { }
}

export class CreateDevicesSuccess implements Action {
    readonly type = CREATE_DEVICE_SUCCESS;
    constructor(
        public payload: {
            successRoute: string,
            createdDevice: IDevice,
            loadingDismiss: () => Promise<boolean>;
        }
    ) { }
}

export class CreateDeviceFailure implements Action {
    readonly type = CREATE_DEVICE_FAILURE;
    constructor(
        public payload: {
            error: any,
            loadingDismiss: () => Promise<boolean>;
        }
    ) { }
}

export const READ_DEVICES = '[Devices] Read devices';
export const READ_DEVICES_SUCCESS = '[Devices] Read devices success';
export const READ_DEVICES_FAILURE = '[Devices] Read devices failure';

export class ReadDevices implements Action {
    readonly type = READ_DEVICES;
    constructor(
        public payload: {
            refresherComplete?: () => void
        }
    ) { }
}

export class ReadDevicesSuccess implements Action {
    readonly type = READ_DEVICES_SUCCESS;
    constructor(
        public payload: {
            devices: IDevice[],
            loadingDismiss: () => Promise<boolean>;
            refresherComplete?: () => void
        }
    ) { }
}

export class ReadDevicesFailure implements Action {
    readonly type = READ_DEVICES_FAILURE;
    constructor(
        public payload: {
            error: any,
            loadingDismiss: () => Promise<boolean>;
            refresherComplete?: () => void
        }
    ) { }
}

export const UPDATE_DEVICE = '[Devices] Update device';
export const UPDATE_DEVICE_SUCCESS = '[Devices] Update device success';
export const UPDATE_DEVICE_FAILURE = '[Devices] Update device failure';

export class UpdateDevice implements Action {
    readonly type = UPDATE_DEVICE;
    constructor(
        public payload: {
            successRoute: string,
            deviceId: string,
            deviceUpdate: IDeviceUpdate,
        }
    ) { }
}

export class UpdateDevicesSuccess implements Action {
    readonly type = UPDATE_DEVICE_SUCCESS;
    constructor(
        public payload: {
            successRoute: string,
            updatedDevice: IDevice,
            loadingDismiss: () => Promise<boolean>;
        }
    ) { }
}

export class UpdateDeviceFailure implements Action {
    readonly type = UPDATE_DEVICE_FAILURE;
    constructor(
        public payload: {
            error: any,
            loadingDismiss: () => Promise<boolean>;
        }
    ) { }
}

export const DELETE_DEVICE = '[Devices] Delete device';
export const DELETE_DEVICE_SUCCESS = '[Devices] Delete device success';
export const DELETE_DEVICE_FAILURE = '[Devices] Delete device failure';

export class DeleteDevice implements Action {
    readonly type = DELETE_DEVICE;
    constructor(
        public payload: {
            device: IDevice
        }
    ) { }
}

export class DeleteDevicesSuccess implements Action {
    readonly type = DELETE_DEVICE_SUCCESS;
    constructor(
        public payload: {
            deletedDevice: IDevice,
            loadingDismiss: () => Promise<boolean>;
        }
    ) { }
}

export class DeleteDeviceFailure implements Action {
    readonly type = DELETE_DEVICE_FAILURE;
    constructor(
        public payload: {
            error: any,
            loadingDismiss: () => Promise<boolean>;
        }
    ) { }
}

export const START_DEVICE = '[Devices] Start device';
export const START_DEVICE_SUCCESS = '[Devices] Start device success';
export const START_DEVICE_FAILURE = '[Devices] Start device failure';

export class StartDevice implements Action {
    readonly type = START_DEVICE;
    constructor(
        public payload: {
            device: IDevice
        }
    ) { }
}

export class StartDeviceSuccess implements Action {
    readonly type = START_DEVICE_SUCCESS;
    constructor(
        public payload: {
            device: IDevice
        }
    ) { }
}

export class StartDeviceFailure implements Action {
    readonly type = START_DEVICE_FAILURE;
    constructor(
        public payload: {
            error: any,
            device: IDevice
        }
    ) { }
}

export type DeviceAction =
    | CreateDevice
    | CreateDevicesSuccess
    | CreateDeviceFailure
    | ReadDevices
    | ReadDevicesSuccess
    | ReadDevicesFailure
    | UpdateDevice
    | UpdateDevicesSuccess
    | UpdateDeviceFailure
    | DeleteDevice
    | DeleteDevicesSuccess
    | DeleteDeviceFailure
    | StartDevice
    | StartDeviceSuccess
    | StartDeviceFailure
    ;
