import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class PlatformVisibilityService {

    public isAppActive = true;

    constructor(
        private platform: Platform
    ) { }

    init(): void {

        this.platform.pause.subscribe(() => this._updateActivestate(false));
        this.platform.resume.subscribe(() => this._updateActivestate(true));
    }

    private _updateActivestate(active: boolean): void {

        console.log(active ? 'App active' : 'App inactive');
        this.isAppActive = active;
    }
}
