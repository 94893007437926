import { Injectable } from '@angular/core';

import { LocalNotifications, LocalNotificationSchema, PendingLocalNotificationSchema, } from '@capacitor/local-notifications';

import { LocalNotificationsService } from './local-notifications.service';
import { IDevice, IStartupReminder } from '@app/shared/model';
import {
    INotificationExtra,
    IStartupDeviceNotificationExtra,
    NotificationActionType,
    DayOfWeek,
    NotificationType
} from '../model';
import { nameof } from '../util';

const STARTUP_REMINDER_WEEKDAY_MAP: { [weekday: number]: string } = {
    [DayOfWeek.Monday]: nameof<IStartupReminder>('monday'),
    [DayOfWeek.Tuesday]: nameof<IStartupReminder>('tuesday'),
    [DayOfWeek.Wednesday]: nameof<IStartupReminder>('wednesday'),
    [DayOfWeek.Thursday]: nameof<IStartupReminder>('thursday'),
    [DayOfWeek.Friday]: nameof<IStartupReminder>('friday'),
    [DayOfWeek.Saturday]: nameof<IStartupReminder>('saturday'),
    [DayOfWeek.Sunday]: nameof<IStartupReminder>('sunday'),
};

@Injectable()
export class StartupReminderService {

    constructor(
        private localNotificationService: LocalNotificationsService
    ) { }

    async scheduleRemindersForDevice(device: IDevice): Promise<void> {

        try {

            // Cancel all existing notifacations for the device since we always recreate all reminders
            await this.cancelReminderForDevice(device);

            if (device.startupReminder.enabled) {

                const nextNotificationId: number = await this.localNotificationService.getNextNoficationId();

                const notifications = this._createStartupDeviceNotifications(device, nextNotificationId);
                const createdNotificationIds = await this.localNotificationService.schedule(notifications);
                console.log(`Created ${createdNotificationIds.length} for device '${device.name}'. ` +
                    `Ids: ${createdNotificationIds.join(', ')}`);
            }

        } catch (ex) {

            console.error(ex);
        }
    }

    async cancelReminderForDevice(device: IDevice): Promise<number[]> {

        try {

            const { notifications: pendingNotifications } = await LocalNotifications.getPending();

            const pendingNotificationsForDevice: PendingLocalNotificationSchema[] = pendingNotifications.filter(notification => {

                return (notification.extra as INotificationExtra).type === 'StartDevice' &&
                    (notification.extra as IStartupDeviceNotificationExtra).deviceId === device.id;
            });

            const idsToCancel: number[] = pendingNotificationsForDevice.map(n => n.id);
            if (idsToCancel.length > 0) {

                await this.localNotificationService.cancel(idsToCancel);
                console.log(`Canceled ${idsToCancel.length} notifications for device '${device.name}'`);
            }

            return idsToCancel;

        } catch (ex) {

            console.error(ex);
        }
    }

    private _createStartupDeviceNotifications(device: IDevice, nextNotificationId: number): LocalNotificationSchema[] {

        const notifications: LocalNotificationSchema[] = [];

        for (let weekday = 1; weekday <= 7; ++weekday) {

            // Continue if startup reminder is not enabled for weekday
            if (!this._startupReminderEnabledForWeekday(weekday, device)) {
                continue;
            }

            const timeSplitted = device.startupReminder.time.split(':');
            const hour = parseInt(timeSplitted[0], 10);
            const minute = parseInt(timeSplitted[1], 10);

            const notificationExtra: IStartupDeviceNotificationExtra = {
                type: NotificationType.StartDevice,
                deviceId: device.id,
                schedule: {
                    weekday,
                    hour,
                    minute
                }
            };

            notifications.push({
                title: 'Startup Reminder',
                body: `Device: ${device.name}`,
                id: nextNotificationId,
                schedule: {
                    on: {
                        day: this._weekDayToScheduleDay(weekday),
                        hour,
                        minute
                    }
                },
                actionTypeId: NotificationActionType.StartDevice,
                extra: notificationExtra
            });

            nextNotificationId++;
        }

        return notifications;
    }

    private _weekDayToScheduleDay(weekDay: DayOfWeek): number {
        // convert monday - sunday num to sunday - saturday num
        return [, 2, 3, 4, 5, 6, 7, 1][weekDay];
    }

    private _startupReminderEnabledForWeekday(weekday: DayOfWeek, device: IDevice): boolean {

        return device.startupReminder[STARTUP_REMINDER_WEEKDAY_MAP[weekday]] === true;
    }
}
