import { IWolSetup } from '../model';

export class BaseService {

    protected createAuthHeaders(wolSetup: IWolSetup): { [header: string]: string } {

        const basicAuth = 'Basic ' + btoa(wolSetup.user + ':' + wolSetup.password);
        return {
            Authorization: basicAuth,
            'Bypass-Tunnel-Reminder': 'true'
        };
    }
}