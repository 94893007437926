import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage';

import { Observable, from } from 'rxjs';
import { map } from 'rxjs/operators';

import { IWolSetup } from '../model';

const KEY_WOL_SETUP = 'wol_setup';

@Injectable({ providedIn: 'root' })
export class StorageService {

    constructor(
        private storage: Storage
    ) { }

    getWolSetup(): Observable<IWolSetup | null> {

        return from(this.storage.create()
            .then(() => this.storage.get(KEY_WOL_SETUP)));
    }

    isWolSetupValid(): Observable<boolean> {

        return this.getWolSetup().pipe(
            map(setup => !!setup)
        );
    }

    setWolSetup(wolSetup: IWolSetup): Observable<any> {

        return from(this.storage.set(KEY_WOL_SETUP, wolSetup));
    }

}
