import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDeviceReducer from './device.reducer';

export interface IWolState {
    devices: fromDeviceReducer.IDeviceState;
}

export const reducers: ActionReducerMap<IWolState> = {
    devices: fromDeviceReducer.reducer
};

export const getWolFeatureSelector = createFeatureSelector<IWolState>('wol');
