import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

import { StorageService } from './storage.service';
import { IDevice } from '@app/shared/model';
import { makeApiUrl } from '../util';
import { WOL_API_VERSION } from '../core.tokens';
import { BaseService } from './base-service';

const API_ROUTE_WAKE = 'wake';

@Injectable({ providedIn: 'root' })
export class WakeService extends BaseService {

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        @Inject(WOL_API_VERSION) private wolApiVersion: string
    ) {
        super();
    }

    wakeDevice(device: IDevice): Observable<any> {

        return this.storageService
            .getWolSetup().pipe(
                mergeMap(wolSetup => {

                    const payload = {
                        macAddress: device.macAddress
                    };

                    const url = makeApiUrl(wolSetup.url, this.wolApiVersion, API_ROUTE_WAKE);

                    return this.http.post(url, payload, {
                        headers: {
                            ...this.createAuthHeaders(wolSetup)
                        }
                    }).pipe(
                        catchError(error => throwError(error))
                    );
                })
            );
    }
}
