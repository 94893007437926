export enum NotificationActionType {
    StartDevice = 'START_DEVICE'
}

export enum StartDeviceActions {
    Start = 'Start'
}

export const NotificationDefaultActionIdentifier = 'tap';
export const NotificationDismissActionIdentifier = 'dismiss';
