import { Injectable } from '@angular/core';
import { CanLoad, Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { StorageService } from '@app/core/services';


@Injectable({
    providedIn: 'root'
})
export class CheckSetupGuard implements CanLoad, CanActivate {

    constructor(
        private router: Router,
        private storageService: StorageService
    ) { }

    canLoad(): Observable<boolean> {

        return this.storageService.isWolSetupValid()
            .pipe(
                map(isValid => {

                    if (isValid) {

                        this.router.navigate(['/app', 'main', 'wol']);
                        return false;
                    }

                    return true;
                })
            );
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {

        return this.storageService.isWolSetupValid()
            .pipe(
                map(isValid => {

                    if (isValid) {
                        return true;
                    }

                    this.router.navigate(['setup']);
                    return false;
                })
            );
    }
}
