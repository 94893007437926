import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { IonicStorageModule } from '@ionic/storage-angular';

import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';

import { CORE_SERVICES } from './services';

import { CORE_EFFECTS } from './store/effects';
import { reducers } from './store/reducers';

import { WOL_API_VERSION } from './core.tokens';

import { metaReducers } from './store';

@NgModule({
    imports: [
        HttpClientModule,
        IonicStorageModule,
        StoreModule.forRoot(reducers, { metaReducers }),
        EffectsModule.forRoot(CORE_EFFECTS),
        StoreDevtoolsModule.instrument({
            name: 'Pi WOL'
        })
    ],
    providers: [
        ...CORE_SERVICES,
        { provide: WOL_API_VERSION, useValue: 'v1' }
    ]
})
export class CoreModule { }
