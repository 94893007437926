import { IDevice } from '@app/shared/model';

import * as fromActions from '../actions/device.actions';

export interface IDeviceState {
    entities: { [id: string]: IDevice };
    loading: boolean;
    loaded: boolean;
    startingDevices: { [id: string]: boolean };
}

export const initialState: IDeviceState = {
    entities: {},
    loaded: false,
    loading: false,
    startingDevices: {}
};

export function reducer(state = initialState, action: fromActions.DeviceAction): IDeviceState {

    switch (action.type) {

        case fromActions.CREATE_DEVICE:
        case fromActions.UPDATE_DEVICE:
        case fromActions.DELETE_DEVICE: {

            return {
                ...state,
                loading: true
            };
        }

        case fromActions.CREATE_DEVICE_SUCCESS: {

            const { createdDevice } = action.payload;

            const entities = {
                ...state.entities,
                [createdDevice.id]: createdDevice
            };

            return {
                ...state,
                entities,
                loaded: true,
                loading: false
            };
        }

        case fromActions.READ_DEVICES: {

            return {
                ...state,
                entities: {},
                loaded: false,
                loading: true
            };
        }

        case fromActions.READ_DEVICES_SUCCESS: {

            const { devices } = action.payload;

            const entities = devices.reduce(
                (_entities: { [id: string]: IDevice }, device: IDevice) => {
                    return {
                        ..._entities,
                        [device.id]: device,
                    };
                },
                {}
            );

            return {
                ...state,
                entities,
                loading: false,
                loaded: true
            };
        }

        case fromActions.UPDATE_DEVICE_SUCCESS: {

            const { updatedDevice } = action.payload;

            const entities = {
                ...state.entities,
                [updatedDevice.id]: updatedDevice
            };

            return {
                ...state,
                entities,
                loading: false
            };
        }

        case fromActions.DELETE_DEVICE_SUCCESS: {

            const { deletedDevice } = action.payload;

            const { [deletedDevice.id]: removed, ...entities } = state.entities;

            return {
                ...state,
                entities,
                loading: false
            };
        }

        case fromActions.CREATE_DEVICE_FAILURE:
        case fromActions.READ_DEVICES_FAILURE:
        case fromActions.UPDATE_DEVICE_FAILURE:
        case fromActions.DELETE_DEVICE_FAILURE: {

            return {
                ...state,
                loading: false
            };
        }

        case fromActions.START_DEVICE: {

            const { device } = action.payload;

            const startingDevices = {
                ...state.startingDevices,
                [device.id]: true
            };

            return {
                ...state,
                startingDevices
            };
        }

        case fromActions.START_DEVICE_SUCCESS:
        case fromActions.START_DEVICE_FAILURE: {

            const { device } = action.payload;

            const startingDevices = {
                ...state.startingDevices,
                [device.id]: false
            };

            return {
                ...state,
                startingDevices
            }
        }
    }

    return state;
}
