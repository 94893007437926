import { DayOfWeek } from './day-of-week';

export enum NotificationType {
    StartDevice = 'StartDevice'
}

export interface INotificationExtra {
    readonly type: NotificationType;
}

export interface IStartupDeviceNotificationExtra extends INotificationExtra {
    type: NotificationType.StartDevice;
    deviceId: string;
    schedule: {
        weekday: DayOfWeek;
        hour: number,
        minute: number
    };
}

export type NotificationExtra =
    | IStartupDeviceNotificationExtra
    ;
