import { MetaReducer, ActionReducer } from '@ngrx/store';

import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from '@env/environment';


// console.log all actions
export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
    return function (state: any, action: any): any {

        console.log({
            action,
            state: { ...state }
        });
        return reducer(state, action);
    };
}

/**
 * By default, @ngrx/store uses combineReducers with the reducer map to compose
 * the root meta-reducer. To add more meta-reducers, provide an array of meta-reducers
 * that will be composed to form the root meta-reducer.
 */
export const metaReducers: MetaReducer<any>[] = !environment.production
    ? [storeFreeze]
    : [];
